import { css, html, LitElement, nothing } from "lit"
import { atBreakpoint, brandonGrotesque } from "#js/components/styles"
import { msg, updateWhenLocaleChanges } from "@lit/localize"
import { fetchJSON } from "#js/components/http"
import { setLocale } from "#js/components/lit-i18n"
import { styleMap } from "lit/directives/style-map.js"

export class CsatSurvey extends LitElement {
  static properties = {
    participation: { type: String },
    feedback: { type: String },
    nps: { type: Number },
    surveyUrl: { type: String },
    formTitle: { type: String },
    state: { type: String, attribute: false },
  }

  headers = {
    "Content-Type": "application/json",
    "X-CSRFToken": document.querySelector("input[name=csrfmiddlewaretoken]").value,
  }

  participationDisplay = {
    good: {
      emoji: "😊",
      text: () => msg("good"),
    },
    neutral: {
      emoji: "😐",
      text: () => msg("neutral"),
    },
    bad: {
      emoji: "🙁",
      text: () => msg("bad"),
    },
  }

  constructor() {
    super()
    setLocale(globalThis.language)
    updateWhenLocaleChanges(this)
  }

  firstUpdated() {
    const urlParams = new URLSearchParams(globalThis.location.search)
    const participation = urlParams.get("participation")
    if (
      !this.participation && participation &&
      ["good", "neutral", "bad"].includes(participation)
    ) {
      this.postParticipation(participation)
      this.participation = participation
    }
  }

  static styles = css`
    .csat {
      ${brandonGrotesque}

      display: flex;
      align-items: center;
      max-width: 20em;
      width: 100%;
      margin: 0 auto;
    }

    .transition {
      overflow: hidden;
      transition: max-height, opacity, padding-bottom;
      transition-duration: 0.5s;
      transition-timing-function: ease-in-out;
    }

    .emoji {
      flex: 1;
      font-size: 1.2em;
      user-select: none;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 0.8em;
      transition: background-color 0.3s, filter 0.3s;
      padding: 1em;
      border-radius: var(--border-radius);
      cursor: pointer;

      &:hover {
        filter: none !important;
      }

      ${
    atBreakpoint(
      "mobile",
      css`
        font-size: 1em;
        gap: 0.5em;
        padding: 0.75em;
      `,
    )
  }
    }

    .good:hover {
      background-color: var(--background-green);
    }

    .neutral:hover {
      background-color: var(--background-yellow);
    }

    .bad:hover {
      background-color: var(--background-red);
    }

    .star-rating {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
      display: flex;
      flex-flow: row-reverse nowrap;
      justify-content: flex-end;
      height: 2.4em;

      label {
        font-size: 33px;
      }

      &:not(:checked) > input {
        display: none;
      }

      &:not(:checked) > label {
        flex: 0 0 1em;
        line-height: 1;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
        color: var(--darker-gray);
      }

      &:not(:checked) > label::before {
        content: "★";
        vertical-align: middle;
      }

      > input:checked ~ label {
        color: var(--accent-color);
      }

      &:not(:checked) > label:hover,
      &:not(:checked) > label:hover ~ label {
        color: var(--orange-dark);
      }
    }

    ${
    atBreakpoint(
      "mobile",
      css`
        .star-rating label {
          font-size: 28px;
        }
      `,
    )
  }
  `

  render() {
    return html`
      <link rel="stylesheet" href="${globalThis.styleFilePath}"/>
      <div class="column"
           style="${
      this.getTransitionStyle(
        { condition: this.state !== "success", maxHeight: "50em" },
      )
    }"
      >
        ${this.renderForm()}
        ${this.renderNps()}
        ${this.renderFeedback()}
        <ui-button @click="${this.postFinish}"
                   ?disabled="${this.isSubmitDisabled()}"
                   style="margin-left: auto;"
                   class="filled">
          ${msg("done")}!
        </ui-button>
      </div>
      ${this.renderThankYou()}
    `
  }

  renderForm() {
    return html`
      <section id="form" style="padding-bottom: 1em">
        ${this.formTitle && html`<h3>${this.formTitle}</h3>`}
        <div class="csat">
          ${this.getParticipationSelect("bad")}
          ${this.getParticipationSelect("neutral")}
          ${this.getParticipationSelect("good")}
        </div>
      </section>
    `
  }

  getParticipationSelect(participation) {
    return html`
      <div class="emoji ${participation}"
           style="${
      styleMap({
        filter: this.participation === participation ? "none" : "grayscale(100%)",
      })
    }"
           @click="${() => this.postParticipation(participation)}">
        <span style="font-size: 2em;">
          ${this.participationDisplay[participation].emoji}
        </span>
        <span style="font-size: 0.8em">
          ${this.participationDisplay[participation].text()}
        </span>
      </div>
    `
  }

  renderNps() {
    return html`
      <section id="nps" class="transition"
               style="${
      this.getTransitionStyle(
        { condition: this.participation === "good", paddingBottom: "1em" },
      )
    }">
        <h4>${msg("How likely are you to recommend voiio?")}</h4>
        <div class="star-rating">
          ${this.getNpsSelect()}
        </div>
      </section>
    `
  }

  renderFeedback() {
    return html`
      <section id="feedback" class="material transition"
               style="${
      this.getTransitionStyle(
        { condition: this.participation },
      )
    }">
        <p>
          <textarea id="feedback"
                    rows="3"
                    style="resize: none;"
                    @input="${(event) => {
      this.feedback = event.target.value
    }}"
                    name="feedback">${
      this.feedback || this.nonParticipationMessage || ""
    }</textarea>
          <label for="feedback">${msg("additional comment")}</label>
        </p>
      </section>
    `
  }

  getNpsSelect() {
    if (this.participation === "good") {
      return html`
        ${
        Array.from(
          { length: 10 },
          (_, i) => i + 1,
        ).reverse().map((i) => {
          return html`
            <input type="radio" name="nps" id="star-${i}"
                   @change="${() => this.postNPS(i)}" ?checked="${this.nps === i}"/>
            <label for="star-${i}"></label>
          `
        })
      }
      `
    }
    return nothing
  }

  renderThankYou() {
    return html`
      <section id="success" class="transition"
               style="${
      this.getTransitionStyle({ condition: this.state === "success" })
    };">
        <h3 style="margin: 0;">
          ${msg("Thank you for your feedback. 🤩")}
        </h3>
      </section>
    `
  }

  isSubmitDisabled() {
    return !(
      (this.participation === "good" && this.nps) ||
      this.participation === "neutral" ||
      this.participation === "bad"
    )
  }

  getTransitionStyle({ condition, maxHeight = "10em", paddingBottom = "0" }) {
    return styleMap(
      {
        "max-height": condition ? maxHeight : "0",
        "padding-bottom": condition ? paddingBottom : "0",
        opacity: condition ? "1" : "0",
      },
    )
  }

  postParticipation(participation) {
    const data = { participation }
    if (participation !== "good") {
      this.nps = null
      data.nps = null
    }
    fetchJSON(this.surveyUrl, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(data),
    }).then(() => {
      this.participation = participation
    })
  }

  postNPS(score) {
    fetchJSON(this.surveyUrl, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify({
        participation: this.participation,
        nps: score,
      }),
    }).then(() => {
      this.nps = score
    })
  }

  postFinish() {
    const data = {
      participation: this.participation,
      is_finished: true,
      feedback_message: this.feedback,
    }

    if (this.participation === "good") {
      data.nps = this.nps
    }

    fetchJSON(this.surveyUrl, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(data),
    }).then(() => {
      this.state = "success"
    })
  }
}

globalThis.customElements.define("csat-survey", CsatSurvey)
